const cityConfig = {
    toronto: {
        locationId: 13929,
        locationName: 'Toronto',
        badgeGroup: 'ontario',
    },

    miami: {
        locationId: 18689,
        locationName: 'Miami-Dade',
        badgeGroup: 'florida',
    },
    
    kitchener: {
        locationId: 15585,
        locationName: 'Kitchener-Waterloo',
        badgeGroup: 'ontario',
    },

    waterloo: {
        locationId: 15585,
        locationName: 'Kitchener-Waterloo',
        badgeGroup: 'ontario',
    },

    cambridge: {
        locationId: 15585,
        locationName: 'Cambridge',
        badgeGroup: 'ontario',
    },

    guelph: {
        locationId: 15585,
        locationName: 'Guelph',
        badgeGroup: 'ontario',
    },

    brantford: {
        locationId: 15585,
        locationName: 'Brantford',
        badgeGroup: 'ontario',
    },

    edmonton: {
        locationId: 13337,
        locationName: 'Edmonton',
        badgeGroup: 'alberta',
    },

    ottawa: {
        locationId: 15304,
        locationName: 'Ottawa',
        badgeGroup: 'quebec',
    },

    hamilton: {
        locationId: 35818,
        locationName: 'Hamilton',
        badgeGroup: 'ontario',
    },

    niagara: {
        locationId: 35818,
        locationName: 'Niagara',
        badgeGroup: 'ontario',
    },
    
    houston: {
        locationId: 35818,
        locationName: 'Houston',
        badgeGroup: 'texas',
    },
};

export default cityConfig;