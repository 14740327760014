import React from 'react';
import QuoteForm from '../components/Quote/QuoteForm';
import BadgeSection from '../components/Badges/BadgeSection';
import WideCtaSection from '../components/CTA/WideCtaSection';

function Quote({ cityData, locationInfo }) {
  return (
    <div>
        <QuoteForm 
          cityData={cityData}
          locationInfo={locationInfo}
        />
        <WideCtaSection 
        cityData={cityData}
        locationInfo={locationInfo}
      />
        <BadgeSection
        cityData={cityData}
        locationInfo={locationInfo}
      />
    </div>
  );
}

export default Quote;