import React from 'react';
import BookNowMicrosite from '../components/BookNow/BookNowMicrosite';


function BookNow({ cityData, locationInfo }) {
  return (
    <div>
        <BookNowMicrosite 
          locationInfo={locationInfo}
          cityData={cityData}
        />
    </div>
  );
}

export default BookNow;