import React from 'react';
import aboutImage from '../../assets/images/resources/truck-junk.png';
import { FaArrowRight } from 'react-icons/fa';

function AboutSection() {
    return (
        <section className="about-one">
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-lg-7">
                        <div className="about-one__right">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">No hidden fees, ever!</span>
                                <h2 className="section-title__title">All-inclusive rates</h2>
                            </div>
                            <p className="about-one__text">
                            Pricing includes our friendly two-person team, 
                            who are fully licensed and insured, and all disposal and environmental fees.</p>
                            <br />
                            <p className="about-one__text">
                            Our all-inclusive rates includes everything:</p>
                            <br />
                            <ul className="list-unstyled about-quality__points">
                                <li>
                                    <div className="icon">
                                        <FaArrowRight />
                                    </div>
                                    <div className="text">
                                        <p>Fully licensed &amp; insured teams</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <FaArrowRight />
                                    </div>
                                    <div className="text">
                                        <p>We do all the lifting &amp; loading</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <FaArrowRight />
                                    </div>
                                    <div className="text">
                                        <p>All disposal fees are included</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <FaArrowRight />
                                    </div>
                                    <div className="text">
                                        <p>No hidden fees, ever!</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-5">
                        <div className="about-one__left">
                            <div className="about-one__img float-bob-y">
                                <img src={aboutImage} alt="" />
                            </div>
                            {/* <div className="about-one__happy-customer">
                                <div className="about-one__happy-customer-icon-worker">
                                    <span className="icon-reliability"></span>
                                </div>
                                <div className="text">
                                    <h3>Trusted by <span className="odometer" data-count="6700">00</span><span
                                            className="happy-customer-plus">+</span> <br /> happy customers</h3>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutSection;