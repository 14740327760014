import React from 'react';


function CouponSection() {
    return (
        <section className="coupon-offer">
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-lg-4">
                        <div className="coupon-offer__left">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">Discounts & Offers</span>
                                <h2 className="section-title__title">Coupons &amp; Promos</h2>
                            </div>
                            <p className="coupon-offer__text">Simply show your truck team the promo when they arrive:
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-8">
                        <div className="coupon-offer__right">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="coupon-offer__single">
                                        <div className="coupon-offer__shape-1">
                                        </div>
                                        <h2 className="discount-amount"><span className="doller-sign">$</span>50<span
                                                className="offer">Off</span></h2>
                                        <h3 className="coupon-offer__discount-text">Full Load
                                            <br /> or more...</h3>
                                        <div className="coupon-offer__bottom-text">
                                            <p>Get a Quote Today!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="coupon-offer__single coupon-offer__single-2">
                                        <div className="coupon-offer__shape-1">
                                        </div>
                                        <h2 className="discount-amount"><span className="doller-sign">$</span>20<span
                                                className="offer">Off</span></h2>
                                        <h3 className="coupon-offer__discount-text">Half Load
                                            <br /> or more...</h3>
                                        <div className="coupon-offer__bottom-text coupon-offer__bottom-text-2">
                                            <p>Get a Quote Today!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CouponSection;