import { database } from '../firebaseConfig';

export const handlePhoneChange = (setPhone, event) => {
    const value = event.target.value.replace(/\D/g, ''); // Remove non-digits
    let formattedValue = '';

    // Formatting logic
    if (value.length > 0) {
        formattedValue += value.substring(0, 3);
    }
    if (value.length > 3) {
        formattedValue += '-' + value.substring(3, 6);
    }
    if (value.length > 6) {
        formattedValue += '-' + value.substring(6, 10);
    }

    setPhone(formattedValue);
};

export const handleNameChange = (setName, event) => {
    const value = event.target.value;
    if (/^[a-zA-Z\s]*$/.test(value)) {
        setName(value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '));
    }
};

export const handlePostalChange = (setPostalCode, event) => {
    const value = event.target.value.toUpperCase().replace(/[^a-zA-Z0-9]/g, '');
    if (value.length <= 6) {
        setPostalCode(value.toLowerCase());
    }
};

export const handleZipChange = (setZip, event) => {
    const value = event.target.value.replace(/\D/g, ''); // Remove non-digits
    if (value.length <= 5) {
        setZip(value);
    }
};

export const handleMessageChange = (setMessage, event) => {
    const value = event.target.value.replace(/[^a-zA-Z0-9 .,]/g, '');
    setMessage(value);
};

export const handleSubmit = async (formState, cityData, onSuccess, onError) => {
    const {
        name, phone, postalCode, message, 
        street, city, state, unit, countryName, isTimeSensitive, zipCode, propertyType, endTime, selectedDate, timeSlot
    } = formState;

    console.log("Submitting form with state:", formState); // Log the form state being submitted

    try {
        // Fetch the user's IP address
        const ipResponse = await fetch('https://api.ipify.org?format=json');
        const ipData = await ipResponse.json();
        const userIp = ipData.ip;

        console.log("User IP:", userIp); // Log the fetched IP address

        // Construct the new lead object
        const newJob = {
            brand_id: 1,
            cus_address: {
                street: street,
                unit: unit,
                city: city,
                state: state,
                zip: zipCode || postalCode || '',
                country: countryName
            },
            cus_email: 'Unavailable',
            cus_name: name,
            cus_phone: phone,
            date: selectedDate, // Use selected date or current date
            description: message,
            end_time: endTime,    // Use endTime or timeSlot if endTime is not available
            ip_address: userIp,
            is_time_sensitive: isTimeSensitive,
            sensitive_reason: '',
            job_status: 'active',
            property_type: propertyType,
            location_id: cityData.locationId || '',
            start_time: timeSlot,
            type: 'Full Service'
        };

        console.log("New job object:", newJob); // Log the new job object being sent

        // Send the new lead to Firebase
        await database.ref('jobs').push(newJob)
            .then(() => {
                console.log("Job successfully added to Firebase"); // Success log
                onSuccess();
            })
            .catch((error) => {
                console.error("Error adding job to Firebase:", error); // Log errors from Firebase
                onError(error);
            });
    } catch (error) {
        console.error("Error in handleSubmit function:", error); // Log any errors in the try-catch block
        onError(error);
    }
};

