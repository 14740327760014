import React, { useState, useEffect } from 'react';
import { useLocation, BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import HomeBookNow from './pages/HomeBookNow';
import ThankYou from './pages/ThankYou';
import CityLandingPage from './CityLandingPage';
import CurrentCityContext from './CurrentCityContext';
import cityConfig from './cityConfig';

// ScrollToTop component
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  const [currentCity, setCurrentCity] = useState(null);

  return (
    <CurrentCityContext.Provider value={{ currentCity, setCurrentCity, cityConfig }}>
      <Router>
        <ScrollToTop />
        <div className="page-wrapper">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/:city/*" element={<CityLandingPage />} />
            <Route path="/book" element={<HomeBookNow />} />
            <Route path="/thankyou" element={<ThankYou />} />
          </Routes>
        </div>
      </Router>
    </CurrentCityContext.Provider>
  );
}

export default App;
