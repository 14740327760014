import React from 'react';
import FaqSection from '../components/Faq/FaqSection';
import HeroSlim from '../components/Hero/HeroSlim';

function FAQ({ cityData, locationInfo }) {
  return (
    <div>
        <HeroSlim 
          cityData={cityData}
          locationInfo={locationInfo}
          heroTitle="FAQ"
        />
        <FaqSection
          locationInfo={locationInfo}
        />
    </div>
  );
}

export default FAQ;