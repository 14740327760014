import React from 'react';
import galleryImage1 from '../../assets/images/resources/garage-clearout.png';
import galleryImage2 from '../../assets/images/resources/construction-cleanup.png';
import galleryImage3 from '../../assets/images/resources/shed-before.png';
import galleryImage4 from '../../assets/images/resources/shed-after.png';
import galleryImage5 from '../../assets/images/resources/warehouse-clearout.png';
import galleryImage6 from '../../assets/images/resources/curbside-removal.png';

function GallerySection() {
    return (
        <section className="project-one">
            <div className="project-one__top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8">
                            <div className="project-one__left">
                                <div className="section-title text-left">
                                    <span className="section-title__tagline">Past Removal Gallery</span>
                                    <h2 className="section-title__title">Junk Disappears like Magic ✨</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="project-one__bottom">
                <div className="container">
                <div className="row">
                        {/* Gallery item 1 */}
                        <div className="col-lg-2 col-md-4 col-sm-4">
                            <div className="project-one__single">
                                <div className="project-one__img">
                                    <img src={galleryImage1} alt="" />
                                    <div className="project-one__img-bg"></div>
                                </div>
                            </div>
                        </div>
                        {/* Gallery item 2 */}
                        <div className="col-lg-2 col-md-4 col-sm-4">
                            <div className="project-one__single">
                                <div className="project-one__img">
                                    <img src={galleryImage2} alt="" />
                                    <div className="project-one__img-bg"></div>
                                </div>
                            </div>
                        </div>
                        {/* Gallery item 3 */}
                        <div className="col-lg-2 col-md-4 col-sm-4">
                            <div className="project-one__single">
                                <div className="project-one__img">
                                    <img src={galleryImage3} alt="" />
                                    <div className="project-one__img-bg"></div>
                                </div>
                            </div>
                        </div>
                        {/* Gallery item 4 */}
                        <div className="col-lg-2 col-md-4 col-sm-4">
                            <div className="project-one__single">
                                <div className="project-one__img">
                                    <img src={galleryImage4} alt="" />
                                    <div className="project-one__img-bg"></div>
                                </div>
                            </div>
                        </div>

                        {/* Gallery item 5 */}
                        <div className="col-lg-2 col-md-4 col-sm-4">
                            <div className="project-one__single">
                                <div className="project-one__img">
                                    <img src={galleryImage5} alt="" />
                                    <div className="project-one__img-bg"></div>
                                </div>
                            </div>
                        </div>
                        
                        {/* Gallery item 6 */}
                        <div className="col-lg-2 col-md-4 col-sm-4">
                            <div className="project-one__single">
                                <div className="project-one__img">
                                    <img src={galleryImage6} alt="" />
                                    <div className="project-one__img-bg"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default GallerySection;