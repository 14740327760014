import React, { useContext } from 'react';
import { Link } from 'react-router-dom'; 
import CurrentCityContext from '../../CurrentCityContext';

function OverlapCta() {
    const { currentCity } = useContext(CurrentCityContext);

    // Function to create URL with city prefix
    const createCityLink = (path) => {
        return currentCity ? `/${currentCity}${path}` : path;
    };
    
    return (
        <section className="request-bar">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="request-bar__inner">
                            <div className="request-bar__bg"></div>
                            <div className="request-bar__left">
                                <div className="icon">
                                    <span className="icon-right-arrow"></span>
                                </div>
                                <div className="request-bar__content">
                                    <p>Ready to declutter?</p>
                                    <h3>Book your free estimate online now!</h3>
                                </div>
                            </div>
                            <div className="request-bar__right">
                                <Link to={createCityLink("/book-now")} className="thm-btn request-bar__btn">Book Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OverlapCta;