import React from 'react';
import HomeSection from '../components/Home/HomeSection';

function Home({ locationInfo }) {
  return (
    <div>
        <HomeSection />
    </div>
  );
}

export default Home;