export function loadGoogleMapsScript(callback) {
    if (window.google && window.google.maps && window.google.maps.places) {
        callback(); // Google Maps API already loaded and places library available
        return;
    }

    // Check if the script tag already exists
    const existingScript = document.querySelector('script[src*="googleapis.com/maps/api/js"]');
    if (existingScript) {
        // If the script is still loading, add a load event listener
        if (!existingScript.hasAttribute('data-loaded')) {
            existingScript.addEventListener('load', () => {
                existingScript.setAttribute('data-loaded', 'true');
                if (window.google && window.google.maps && window.google.maps.places) {
                    callback();
                }
            });
        }
        return;
    }

    // Create a new script tag
    const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    // Define initMap globally to satisfy the callback requirement
    window.initMap = () => {
        script.setAttribute('data-loaded', 'true');
        callback();
    };
}
