const badgeGroup = {
    ontario: {
        badge1: 'raptors',
        badge2: 'leafs',
        badge3: 'canada',
        badge4: 'tfc',
        badge5: 'bluejays',
    },
    alberta: {
        badge1: 'flames',
        badge2: 'oilers',
        badge3: 'canada',
        badge4: 'raptors',
        badge5: 'bluejays',
    },
    quebec: {
        badge1: 'senators',
        badge2: 'raptors',
        badge3: 'canada',
        badge4: 'leafs',
        badge5: 'bluejays',
        badge6: 'canadiens',
    },
    florida: {
        badge1: 'heat',
        badge2: 'dolphins',
        badge3: 'marlins',
        badge4: 'panthers',
        badge5: 'seminoles',
        badge6: 'usa',
    },
    texas: {
        badge1: 'longhorns',
        badge2: 'txam',
        badge3: 'cowboys',
        badge4: 'texans',
        badge5: 'rangers',
        badge6: 'mavs',
        badge7: 'usa',
        badge8: 'spurs',
        badge9: 'rockets',
        badge10: 'astros',
        
    },
};

export default badgeGroup;