import React from 'react';
import { Link } from 'react-router-dom';

function WideCtaSection({ cityData, locationInfo }) {
    return (
        <section className="row emmergency-leaks">
            <div className="container">
                {/* <h4 className="this-intro">If you have questions for us,</h4> */}
                <h2 className="this-title">Questions?</h2>
                <h2 className="this-title"><span>Call or Text the {cityData.locationName} team directly! </span></h2>
                <Link className="call-link" to={'tel:'+locationInfo.tracking_phone}>{locationInfo.tracking_phone}</Link>
            </div>
        </section>
    );
}

export default WideCtaSection;