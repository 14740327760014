import React, { useState, useEffect, useContext } from 'react';
import CurrentCityContext from '../../CurrentCityContext';
import { Link } from 'react-router-dom'; 
import { NavLink } from 'react-router-dom';
import { fetchCountryFromIP } from '../../utils/fetchCountryFromIP';
import headerLogoUSA from '../../assets/images/resources/logo-usa.png';
import headerLogoCanada from '../../assets/images/resources/logo-canada.png';
import headerLogo from '../../assets/images/resources/logo.png';
import emailLogo from '../../assets/images/icon/email-icon-1.png';
import phoneLogo from '../../assets/images/icon/phone-icon-1.png';
import backgroundImage from '../../assets/images/shapes/main-header__top-pattern-bg.png';
import StickyHeader from './StickyHeader';
import { MdLocalPhone, MdClose } from "react-icons/md";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaBars } from 'react-icons/fa';
import { FaArrowRight } from 'react-icons/fa';

function Header({ cityData, locationInfo }) {
    const { currentCity } = useContext(CurrentCityContext);
    const [country, setCountry] = useState('');
    const [isSticky, setIsSticky] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const handleScroll = () => {
        const offset = window.scrollY;
        const headerHeight = document.querySelector('.main-header').offsetHeight;
    
        // for example, headerHeight + 100 or any other number that works for your layout
        if (offset > headerHeight + 5) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };

    useEffect(() => {
        fetchCountryFromIP().then(ipData => {
            if (ipData) {
                setCountry(ipData.country_name);
                console.log('User location:', ipData.city, ipData.region_code, ipData.postal);
            }
        });
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const renderLogo = () => {
        if (country === 'United States') {
            return <img src={headerLogoUSA} width="250" alt="JUNK CHUCKERS USA" />;
        } else if (country === 'Canada') {
            return <img src={headerLogoCanada} width="250" alt="JUNK CHUCKERS Canada" />;
        }
        // Default logo if country is neither USA nor Canada, or if not yet determined
        return <img src={headerLogo} width="181" alt="JUNK CHUCKERS Logo" />;
    };

    // Function to create URL with city prefix
    const createCityLink = (path) => {
        return currentCity ? `/${currentCity}${path}` : path;
    };

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const MobileMenu = () => (
        <div className={`mobile-menu ${mobileMenuOpen ? 'open' : ''}`}>
            <div className="mobile-menu__logo">
                <img src={headerLogo} alt="Logo" /> {/* Replace with your logo */}
            </div>
            <div className="mobile-menu__phone">
                <a href={'tel:' + locationInfo.tracking_phone}>
                    <MdLocalPhone /> {locationInfo.tracking_phone}
                </a>
            </div>
            <button className="mobile-menu__close-btn" onClick={toggleMobileMenu}>
                <MdClose />
            </button>
            <nav className="mobile-menu__nav">
                <ul className="mobile-menu__list">
                    <li><NavLink to={createCityLink("/what-we-take")} onClick={toggleMobileMenu}><FaLongArrowAltRight /> What We Take</NavLink></li>
                    <li><NavLink to={createCityLink("/how-it-works")} onClick={toggleMobileMenu}><FaLongArrowAltRight /> How It Works</NavLink></li>
                    <li><NavLink to={createCityLink("/reviews")} onClick={toggleMobileMenu}><FaLongArrowAltRight /> Reviews</NavLink></li>
                    <li><NavLink to={createCityLink("/get-quote")} onClick={toggleMobileMenu}><FaLongArrowAltRight /> Free Quote</NavLink></li>
                    <li><NavLink to={createCityLink("/book-now")} onClick={toggleMobileMenu}><FaLongArrowAltRight /> Book Now!</NavLink></li>
                </ul>
            </nav>
        </div>
    );

    return (
    <>
        <header className="main-header main-header--one">
            <div className="main-header__top">
                <div className="top-border"></div>
                <div className="bottom-border"></div>
                    <div className="main-header__top-pattern-bg"
                    style={{
                        backgroundImage: {backgroundImage}
                    }}
                    >
                </div>
                <div className="container">
                    <div className="main-header__top-inner">
                        <div className="main-header__top-left">
                            <div className="main-header__logo">
                                    <Link to={createCityLink("/")}>
                                        {renderLogo()}
                                    </Link>
                            </div>
                        </div>
                        <Link to="#" 
                            className="mobile-sticky-fixed-cta mobile-only" 
                            onClick={toggleMobileMenu}
                            style={{
                                fontSize: '20px',
                                color: 'var(--chize-base)',
                                paddingLeft: '20px'
                            }}
                            >
                                    <FaBars /> MENU
                                </Link>
                        <div className="main-header__top-right">
                            <ul className="list-unstyled main-header__address-list">
                                <li>
                                    <div className="icon">
                                        <img src={emailLogo} alt="" />
                                    </div>
                                    <div className="content">
                                        <p>Text a Photo for a Fast Quote:</p>
                                        <h4>
                                            <Link to={'tel:'+locationInfo.tracking_phone}>{locationInfo.tracking_phone}</Link>
                                        </h4>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                    <img src={phoneLogo} alt="" />
                                    </div>
                                    <div className="content">
                                        <p>Call Us Today:</p>
                                        <h4>
                                        <Link to={'tel:'+locationInfo.tracking_phone}>{locationInfo.tracking_phone}</Link>
                                        </h4>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <nav className="main-menu main-menu--one">
                <div className="main-menu__wrapper">
                    <div className="container">
                        <div className="main-menu__wrapper-inner">
                            <div className="main-menu__wrapper-inner-bg">
                            </div>
                            <div className="main-menu__main-menu-box">
                                <Link to="#" className="mobile-nav__toggler" onClick={toggleMobileMenu}>
                                    <FaBars /> MENU
                                </Link>
                                <ul className="main-menu__list">
                                    <li>
                                    <NavLink to={createCityLink("/")} end activeclasscame="active">Home</NavLink>

                                    </li>
                                    <li>
                                        <NavLink to={createCityLink("/what-we-take")} activeclasscame="active">What We Take</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={createCityLink("/how-it-works")} activeclasscame="active">How It Works</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={createCityLink("/reviews")} activeclasscame="active">Reviews</NavLink>
                                    </li>
                                    {/* <li>
                                        <NavLink to={createCityLink("/faq")} activeclasscame="active">FAQ</NavLink>
                                    </li> */}
                                    <li>
                                        <NavLink to={createCityLink("/get-quote")} activeclasscame="active">Free Quote</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="main-menu__right">
                                <div className="main-menu__search-nav-sidebar-btn">
                                    <div className="main-menu__btn">
                                    <Link to={createCityLink("/book-now")}>Book now 
                                        <FaArrowRight 
                                             style={{
                                                fontSize: '.85em',
                                                marginLeft: '5px'
                                            }} 
                                        />
                                    </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            
        </header>
        <div 
            className="mobile-sticky-cta"
            style={{
                marginTop: '-60px',
                zIndex: '1',
                position: 'relative',
            }}
        >
            <Link to={createCityLink("/get-quote")} className="cta-column">
                <span className="cta-icon"><FaLongArrowAltRight /></span>
                <span className="cta-text">GET QUOTE</span>
            </Link>
            <Link to={'tel:' + locationInfo.tracking_phone} className="cta-column">
                <span className="cta-icon"><MdLocalPhone /></span>
                <span className="cta-text">CALL/SMS</span>
            </Link>
        </div>
        <MobileMenu />

        {isSticky && (
            <div className="sticky-header">
                <StickyHeader 
                    cityData={cityData}
                    locationInfo={locationInfo}
                />
            </div>
        )}
    </>
    );
}

export default Header;