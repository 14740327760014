import React from 'react';
import BookNowHome from '../components/BookNow/BookNowHome';

function HomeBookNow({ locationInfo }) {
  return (
    <div>
        <BookNowHome />
    </div>
  );
}

export default HomeBookNow;