import React from 'react';
import heroImage from '../../assets/images/resources/main-slider-img-1.png';
// import badgeSatisfaction from '../../assets/images/resources/badge-satisfaction.png';
import heroPromo from '../../assets/images/promo/hero-promo.png';
import HeroForm from './HeroForm';

function Hero({ cityData, locationInfo }) {

    return (
        <section className="main-slider">
            <div className="main-slider-bg">
            </div>
            <div className="main-slider__img">
            <img src={heroImage} alt="" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-lg-7"><img className="main-slider__promo" src={heroPromo} alt="" />
                        <div className="main-slider__left">
                            <div className="main-slider__title-box">
                                <span className="main-slider__sub-title">{cityData.locationName}'s</span>
                                <h2 className="main-slider__title">Same Day <br /> Junk Removal</h2>
                                <ul className="additional-services-two__points list-unstyled">
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>Up to 25-40% cheaper than the "other guys"</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>Fully licensed &amp; insured</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>8+ years serving the community</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-5">
                        <div className="main-slider__right">
                            <div className="main-slider__form-box">
                                <h2>Want a free estimte?</h2>
                                <h3>Just tell us what to you have to remove!</h3>
                                <HeroForm
                                    cityData={cityData}
                                    locationInfo={locationInfo}
                                 />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;