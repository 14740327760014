import React from 'react';

function FaqSection ({ locationInfo }) {

    return (
    <>
        {/* <section className="faq-page">
            <div className="container">
                <div className="section-title text-left">
                    <span className="section-title__tagline">FAQ</span>
                    <h2 className="section-title__title">Frequently Asked Questions</h2>
                    <p className="section-title__text">Discover you question from underneath or present your inquiry fromt
                        the submit box.</p>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-lg-6">
                        <div className="faq-page__left">
                            <div className="accrodion-grp" data-grp-name="faq-one-accrodion">
                                <div className="accrodion active">
                                    <div className="accrodion-title">
                                        <h4>Bring to the table win-win survival strategies ?</h4>
                                    </div>
                                    <div className="accrodion-content">
                                        <div className="inner">
                                            <p>Lorem ipsum dolor sit amet, vix an natum labitur eleifd, mel am laoreet
                                                menandri. Ei justo complectitur duo. Ei mundi solet utos soletu possit
                                                quo. Sea cu justo laudem. An utinam consulatu eos, est facilis.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accrodion">
                                    <div className="accrodion-title">
                                        <h4>Override the digital divide with additional
                                            clickthroughs from DevOps ?</h4>
                                    </div>
                                    <div className="accrodion-content">
                                        <div className="inner">
                                            <p>Lorem ipsum dolor sit amet, vix an natum labitur eleifd, mel am laoreet
                                                menandri. Ei justo complectitur duo. Ei mundi solet utos soletu possit
                                                quo. Sea cu justo laudem. An utinam consulatu eos, est facilis.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accrodion">
                                    <div className="accrodion-title">
                                        <h4>At the end of the day, going forward ?</h4>
                                    </div>
                                    <div className="accrodion-content">
                                        <div className="inner">
                                            <p>Lorem ipsum dolor sit amet, vix an natum labitur eleifd, mel am laoreet
                                                menandri. Ei justo complectitur duo. Ei mundi solet utos soletu possit
                                                quo. Sea cu justo laudem. An utinam consulatu eos, est facilis.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accrodion">
                                    <div className="accrodion-title">
                                        <h4>Information highway will close the loop on ?</h4>
                                    </div>
                                    <div className="accrodion-content">
                                        <div className="inner">
                                            <p>Lorem ipsum dolor sit amet, vix an natum labitur eleifd, mel am laoreet
                                                menandri. Ei justo complectitur duo. Ei mundi solet utos soletu possit
                                                quo. Sea cu justo laudem. An utinam consulatu eos, est facilis.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="faq-page__right">
                            <div className="accrodion-grp" data-grp-name="faq-one-accrodion">
                                <div className="accrodion">
                                    <div className="accrodion-title">
                                        <h4>User generated content in real-time will have ?</h4>
                                    </div>
                                    <div className="accrodion-content">
                                        <div className="inner">
                                            <p>Lorem ipsum dolor sit amet, vix an natum labitur eleifd, mel am laoreet
                                                menandri. Ei justo complectitur duo. Ei mundi solet utos soletu possit
                                                quo. Sea cu justo laudem. An utinam consulatu eos, est facilis.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accrodion">
                                    <div className="accrodion-title">
                                        <h4>Normal that has evolved from generation
                                            on the runwaay heading towards ?</h4>
                                    </div>
                                    <div className="accrodion-content">
                                        <div className="inner">
                                            <p>Lorem ipsum dolor sit amet, vix an natum labitur eleifd, mel am laoreet
                                                menandri. Ei justo complectitur duo. Ei mundi solet utos soletu possit
                                                quo. Sea cu justo laudem. An utinam consulatu eos, est facilis.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accrodion">
                                    <div className="accrodion-title">
                                        <h4>Focusing solely on the bottom line ?</h4>
                                    </div>
                                    <div className="accrodion-content">
                                        <div className="inner">
                                            <p>Lorem ipsum dolor sit amet, vix an natum labitur eleifd, mel am laoreet
                                                menandri. Ei justo complectitur duo. Ei mundi solet utos soletu possit
                                                quo. Sea cu justo laudem. An utinam consulatu eos, est facilis.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accrodion">
                                    <div className="accrodion-title">
                                        <h4>Multiple touchpoints for offshoring ?</h4>
                                    </div>
                                    <div className="accrodion-content">
                                        <div className="inner">
                                            <p>Lorem ipsum dolor sit amet, vix an natum labitur eleifd, mel am laoreet
                                                menandri. Ei justo complectitur duo. Ei mundi solet utos soletu possit
                                                quo. Sea cu justo laudem. An utinam consulatu eos, est facilis.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        <div className="service-details__faq">
                                <h2 className="service-details__faq-title">Why Choose Us</h2>
                                <div className="accrodion-grp faq-one-accrodion" data-grp-name="faq-one-accrodion">
                                    <div className="accrodion">
                                        <div className="accrodion-title">
                                            <h4>Duis aute irure dolor in reprehenderit volup.</h4>
                                        </div>
                                        <div className="accrodion-content" style={{display:'none'}}>
                                            <div className="inner">
                                                <p>Expound the actual teachings of the great explorer of the truth, the
                                                    master-builder of human happiness. No one rejects, dislikes, or
                                                    avoids. Expound the actual teachings of the great explorer of the
                                                    truth, the master-builder of human happiness. No one rejects,
                                                    dislikes, or avoids.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accrodion active">
                                        <div className="accrodion-title">
                                            <h4>Sunt in culpa qui officia deserunt mollit.</h4>
                                        </div>
                                        <div className="accrodion-content">
                                            <div className="inner">
                                                <p>Expound the actual teachings of the great explorer of the truth, the
                                                    master-builder of human happiness. No one rejects, dislikes, or
                                                    avoids. Expound the actual teachings of the great explorer of the
                                                    truth, the master-builder of human happiness. No one rejects,
                                                    dislikes, or avoids.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accrodion">
                                        <div className="accrodion-title">
                                            <h4>Sed ut perspiciatis unde omnis iste natus.</h4>
                                        </div>
                                        <div className="accrodion-content" style={{display:'none'}}>
                                            <div className="inner">
                                                <p>Expound the actual teachings of the great explorer of the truth, the
                                                    master-builder of human happiness. No one rejects, dislikes, or
                                                    avoids. Expound the actual teachings of the great explorer of the
                                                    truth, the master-builder of human happiness. No one rejects,
                                                    dislikes, or avoids.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accrodion">
                                        <div className="accrodion-title">
                                            <h4>Excepteur sint occaecat cupidatat proident.</h4>
                                        </div>
                                        <div className="accrodion-content" style={{display: "none"}}>
                                            <div className="inner">
                                                <p>Expound the actual teachings of the great explorer of the truth, the
                                                    master-builder of human happiness. No one rejects, dislikes, or
                                                    avoids. Expound the actual teachings of the great explorer of the
                                                    truth, the master-builder of human happiness. No one rejects,
                                                    dislikes, or avoids.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
    </>
    );
}

export default FaqSection;