import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { handlePhoneChange, handleNameChange, handlePostalChange, handleZipChange, handleMessageChange, handleSubmit } from '../../utils/formHandlers';
import { fetchCountryFromIP } from '../../utils/fetchCountryFromIP';
import { FaArrowRight } from 'react-icons/fa';

function HeroForm({ cityData }) {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [zip, setZip] = useState('');
    const [message, setMessage] = useState('');
    const [country, setCountry] = useState('');

    useEffect(() => {
        fetchCountryFromIP().then(ipData => {
            if (ipData) {
                setCountry(ipData.country_name);
            }
        });
    }, []);

    const formSubmitHandler = (event) => {
        event.preventDefault();
        const formState = { name, phone, postalCode, zip, message };
        
        handleSubmit(formState, cityData, 
            () => {
                // Handle success, e.g., clear form, show success message
                navigate('/thankyou'); // Redirect to the thank you page
            }, 
            (error) => {
                // Handle error, e.g., show error message
                console.error("Error adding lead: ", error);
            }
        );
    };

    return (
        <form 
            onSubmit={formSubmitHandler} 
            className="main-slider__form comment-form-2-validated" 
            noValidate="noValidate"
        >
            <div className="row">
                <div className="col-xl-12">
                    <div className="main-slider__form-input-box">
                        <input type="text" name="location_id" onChange={(e) => handleZipChange(setZip, e)} autoComplete="off" value={cityData.locationId}  hidden />
                        <input 
                            type="text" 
                            placeholder="Name:" 
                            name="name" 
                            value={name}
                            autoComplete="name"
                            onChange={(e) => handleNameChange(setName, e)}
                            maxLength={20}
                            required
                        />
                    </div>
                </div>
                <div className="col-xl-12">
                    <div className="main-slider__form-input-box">
                        <input 
                            type="text" 
                            placeholder="Phone:" 
                            name="phone" 
                            autoComplete="tel"
                            value={phone}
                            onChange={(e) => handlePhoneChange(setPhone, e)}
                            maxLength={12}
                            required
                        />
                    </div>
                </div>
                {!cityData.locationId && (
                <div className="col-xl-12">
                    <div className="main-slider__form-input-box">
                        {country === 'Canada' && (
                        <input 
                            type="text" 
                            placeholder="Postal code:" 
                            name="zip" 
                            autoComplete="zip"
                            value={postalCode.toUpperCase()}
                            onChange={(e) => handlePostalChange(setPostalCode, e)}
                            maxLength={6}
                            required
                        /> 
                        )}
                        {country === 'United States' && (
                        <input 
                            type="text" 
                            placeholder="Zip code:" 
                            name="zip" 
                            autoComplete="zip"
                            value={zip}
                            onChange={(e) => handleZipChange(setZip, e)}
                            maxLength={5}
                            required
                        />
                        )}
                        
                    </div>
                </div>
                )}
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="main-slider__form-input-box text-message-box">
                        <textarea 
                            name="message" 
                            placeholder="What do you want us to remove?" 
                            value={message} 
                            autoComplete="off"
                            onChange={(e) => handleMessageChange(setMessage, e)}
                            required
                        />
                    </div>
                    <div className="main-slider__form-btn-box">
                        <button 
                            type="submit" 
                            className=" thm-btn main-slider__form-btn">
                            Get Your Free Quote
                            <FaArrowRight />
                        </button>
                    </div>
                </div>
                <p><br />Recieve a quote in minutes!</p>
            </div>
        </form>
    );
}

export default HeroForm;