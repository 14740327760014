import React, { useState, useEffect, useContext } from 'react';
import CurrentCityContext from '../../CurrentCityContext';
import { Link } from 'react-router-dom'; 
import { NavLink } from 'react-router-dom';
import { fetchCountryFromIP } from '../../utils/fetchCountryFromIP';
import headerLogoUSA from '../../assets/images/resources/logo-usa.png';
import headerLogoCanada from '../../assets/images/resources/logo-canada.png';
import headerLogo from '../../assets/images/resources/logo.png';
import backgroundImage from '../../assets/images/shapes/main-header__top-pattern-bg.png';
import { MdLocalPhone, MdClose } from "react-icons/md";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaBars } from "react-icons/fa";
import { FaArrowRight } from 'react-icons/fa';

function StickyHeader({ cityData, locationInfo }) {
    const { currentCity } = useContext(CurrentCityContext);
    const [country, setCountry] = useState('');
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    useEffect(() => {
        fetchCountryFromIP().then(ipData => {
            if (ipData) {
                setCountry(ipData.country_name);
            }
        });
    }, []);

    const renderLogo = () => {
        if (country === 'United States') {
            return <img src={headerLogoUSA} width="250" alt="JUNK CHUCKERS USA" />;
        } else if (country === 'Canada') {
            return <img src={headerLogoCanada} width="250" alt="JUNK CHUCKERS Canada" />;
        }
        // Default logo if country is neither USA nor Canada, or if not yet determined
        return <img src={headerLogo} width="181" alt="JUNK CHUCKERS Logo" />;
    };

    // Function to create URL with city prefix
    const createCityLink = (path) => {
        return currentCity ? `/${currentCity}${path}` : path;
    };

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const MobileMenu = () => (
        <div className={`mobile-menu ${mobileMenuOpen ? 'open' : ''}`}>
            <div className="mobile-menu__logo">
                <img src={headerLogo} alt="Logo" /> {/* Replace with your logo */}
            </div>
            <button className="mobile-menu__close-btn" onClick={toggleMobileMenu}>
                <MdClose />
            </button>
            <nav className="mobile-menu__nav">
                <ul className="mobile-menu__list">
                    <li><NavLink to={createCityLink("/what-we-take")} onClick={toggleMobileMenu}><FaLongArrowAltRight /> What We Take</NavLink></li>
                    <li><NavLink to={createCityLink("/how-it-works")} onClick={toggleMobileMenu}><FaLongArrowAltRight /> How It Works</NavLink></li>
                    <li><NavLink to={createCityLink("/reviews")} onClick={toggleMobileMenu}><FaLongArrowAltRight /> Reviews</NavLink></li>
                    <li><NavLink to={createCityLink("/get-quote")} onClick={toggleMobileMenu}><FaLongArrowAltRight /> Free Quote</NavLink></li>
                    <li><NavLink to={createCityLink("/book-now")} onClick={toggleMobileMenu}><FaLongArrowAltRight /> Book Now!</NavLink></li>
                </ul>
            </nav>
        </div>
    );

    return (
    <>
        <header className="main-header main-header--one">
            <div className="main-header__top-sticky">
                <div className="top-border">
                </div>
                <div className="bottom-border"></div>
                    <div className="main-header__top-pattern-bg"
                    style={{
                        backgroundImage: {backgroundImage}
                    }}
                    >
                </div>
                <div className="container">
                    <div className="main-header__top-top-container">
                        <h4 className="main-header__top-top">
                            Call or Text <span className="cta-icon"><MdLocalPhone /></span>
                            <Link to={'tel:'+locationInfo.tracking_phone}>{locationInfo.tracking_phone}</Link>
                        </h4>
                    </div>
                    <div className="main-header__top-inner">
                        <div className="main-header__top-left">
                            <div className="main-header__logo">
                                    <Link to={createCityLink("/")}>
                                        {renderLogo()}
                                    </Link>
                            </div>
                        </div>
                        <div className="main-header__top-right">
                            <nav className="main-menu main-menu--one">
                                <div className="main-menu__wrapper">
                                    <div className="container">
                                        <div className="main-menu__wrapper-inner-sticky">
                                            <div className="">
                                            </div>
                                            <div className="main-menu__main-menu-box">
                                                <Link to="#" className="mobile-nav__toggler" onClick={toggleMobileMenu}>
                                                    <FaBars /> MENU
                                                </Link>
                                                <ul className="main-menu__list">
                                                    {/* <li>
                                                    <NavLink to={createCityLink("/")} end activeclasscame="active">Home</NavLink>

                                                    </li> */}
                                                    <li>
                                                        <NavLink to={createCityLink("/what-we-take")} activeclasscame="active">What We Take</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={createCityLink("/how-it-works")} activeclasscame="active">How It Works</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={createCityLink("/reviews")} activeclasscame="active">Reviews</NavLink>
                                                    </li>
                                                    {/* <li>
                                                        <NavLink to={createCityLink("/faq")} activeclasscame="active">FAQ</NavLink>
                                                    </li> */}
                                                    <li>
                                                        <NavLink to={createCityLink("/get-quote")} activeclasscame="active">Free Quote</NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="main-menu__right">
                                                <div className="main-menu__search-nav-sidebar-btn">
                                                    <div className="main-menu__btn-sticky">
                                                    <Link to={createCityLink("/book-now")}>Book Now 
                                                        <FaArrowRight 
                                                            style={{
                                                                fontSize: '.85em',
                                                                marginLeft: '5px'
                                                            }} 
                                                        />
                                                    </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <MobileMenu />

        <div className="mobile-sticky-cta">
            <Link to={createCityLink("/get-quote")} className="cta-column">
                <span className="cta-icon"><FaLongArrowAltRight /></span>
                <span className="cta-text">GET QUOTE</span>
            </Link>
            <Link to={'tel:' + locationInfo.tracking_phone} className="cta-column">
                <span className="cta-icon"><MdLocalPhone /></span>
                <span className="cta-text">CALL/SMS</span>
            </Link>
        </div>

    </>
    );
}

export default StickyHeader;