import React from 'react';
import aboutImage2 from '../../assets/images/resources/about-2-1.png';
import aboutImage1 from '../../assets/images/shapes/about-two-shape-1.png';

function AboutSectionTwo({ cityData, locationInfo }) {
    return (
        <section className="about-two">
            <div className="about-two__shape-1 float-bob-x">
                <img src={aboutImage1} alt="" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-lg-5">
                        <div className="about-two__left">
                            <div className="about-two__img float-bob-y">
                                <img src={aboutImage2} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-7">
                        <div className="about-two__right">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">About JUNK CHUCKERS {cityData.locationName}</span>
                                <h2 className="section-title__title">
                                Junk removal made simple in {cityData.locationName} &amp; beyond
                                </h2>
                            </div>
                            <p className="about-two__text">We're your local junk removal company in {cityData.locationName} and the surrounding areas.
                            We gladly handle the tough and dirty stuff - no job is too big or too small for us.</p>
                        </div>
                        
                        <ul className="about-one__points-box list-unstyled">
                                <li>
                                    <div className="about-one__points-list">
                                        <div className="about-one__points-icon">
                                            <span className="icon-satisfaction"></span>
                                        </div>
                                        <h3 className="about-one__points-title">8+ years in the business</h3>
                                    </div>
                                    {/* <p className="about-one__points-text">Our gutter protection simply works better than any
                                        other product.</p> */}
                                </li>
                                <li>
                                    <div className="about-one__points-list">
                                        <div className="about-one__points-icon">
                                            <span className="icon-thumbs-up"></span>
                                        </div>
                                        <h3 className="about-one__points-title">400+ happy customers </h3>
                                    </div>
                                    {/* <p className="about-one__points-text">Our gutter protection simply works better than any
                                        other product.</p> */}
                                </li>
                            </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutSectionTwo;