import React from 'react';
import HowItWorksSection from '../components/HowItWorks/HowItWorksSection';
import HeroSlim from '../components/Hero/HeroSlim';
import ReviewSection from '../components/Testimonials/ReviewSection';
import OverlapCta from '../components/CTA/OverlapCta';
import CouponSection from '../components/Coupons/CouponSection';
import FormTwoColumn from '../components/CTA/FormTwoColumn';
import WideCtaSection from '../components/CTA/WideCtaSection';
import BadgeSection from '../components/Badges/BadgeSection';

function HowItWorks({ cityData, locationInfo }) {
  return (
    <div>
        <HeroSlim 
          heroTitle="How It Works"
          locationInfo={locationInfo}
          cityData={cityData}
        />
        <HowItWorksSection 
          cityData={cityData}
          locationInfo={locationInfo}
        />
        <OverlapCta 
          cityData={cityData}
          locationInfo={locationInfo}
        />
        <ReviewSection 
          cityData={cityData}
          locationInfo={locationInfo}
        />
      <WideCtaSection 
        cityData={cityData}
        locationInfo={locationInfo}
      />
      <CouponSection />
      <FormTwoColumn 
        cityData={cityData}
        locationInfo={locationInfo}
      />
      <BadgeSection
        cityData={cityData}
        locationInfo={locationInfo}
      />
    </div>
  );
}

export default HowItWorks;