import React from 'react';
// import ServiceSection from '../components/Services/ServiceSection';
import AdditionalServicesSectionAlt from '../components/Services/AdditionalServicesSectionAlt';
import Hero from '../components/Hero/Hero';
// import WelcomeSection from '../components/Welcome/WelcomeSection';
import GallerySection from '../components/Gallery/GallerySection';
import AboutSectionTwo from '../components/About/AboutSectionTwo';
// import TestimonialSection from '../components/Testimonials/TestimonialSection';
import CouponSection from '../components/Coupons/CouponSection';
import BadgeSection from '../components/Badges/BadgeSection';
import FormTwoColumn from '../components/CTA/FormTwoColumn';
// import AboutSectionThree from '../components/About/AboutSectionThree';
// import AboutSectionFour from '../components/About/AboutSectionFour';
import OverlapCta from '../components/CTA/OverlapCta';
import AboutSection from '../components/About/AboutSection';
// import AdditionalServicesSection from '../components/Services/AdditionalServicesSection';
// import WelcomeSectionAlt from '../components/Welcome/WelcomeSectionAlt';
import ContactOptionsRow from '../components/CTA/ContactOptionsRow';
import WideCtaSection from '../components/CTA/WideCtaSection';


function City({ cityData, locationInfo }) {
  return (
    <>
      <Hero
        cityData={cityData}
        locationInfo={locationInfo}
      />
      <ContactOptionsRow
        cityData={cityData}
        locationInfo={locationInfo}
      />
      <AboutSectionTwo
        cityData={cityData}
        locationInfo={locationInfo}
      />
      <OverlapCta />
      <AboutSection />
      {/* <WelcomeSection /> */}
      {/* <ServiceSection /> */}
      <AdditionalServicesSectionAlt />
      <GallerySection />
      <CouponSection />
      {/* <TestimonialSection /> */}
      {/* <AboutSectionThree />
      <AboutSectionFour />
      <AdditionalServicesSection />
      <WelcomeSectionAlt /> */}
      <FormTwoColumn 
        cityData={cityData}
        locationInfo={locationInfo}
      />
      <WideCtaSection 
        cityData={cityData}
        locationInfo={locationInfo}
      />
      <BadgeSection
        cityData={cityData}
        locationInfo={locationInfo}
      />
    </>
  );
}

export default City;