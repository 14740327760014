import React from 'react';

function HomeSection() {
    return (
        <p>
            
        </p>
    );
}

export default HomeSection;