import React from 'react';
import AdditionalServicesSectionAlt from '../components/Services/AdditionalServicesSectionAlt';
import AboutSectionTwo from '../components/About/AboutSectionTwo';
import OverlapCta from '../components/CTA/OverlapCta';
import AboutSection from '../components/About/AboutSection'; 
import CouponSection from '../components/Coupons/CouponSection';
import FormTwoColumn from '../components/CTA/FormTwoColumn';
import WideCtaSection from '../components/CTA/WideCtaSection';
import BadgeSection from '../components/Badges/BadgeSection';


function WhatWeTake({ cityData, locationInfo }) {
  return (
    <div>
        <AdditionalServicesSectionAlt />
        <AboutSectionTwo
        cityData={cityData}
        locationInfo={locationInfo}
      />
      <OverlapCta />
      <AboutSection />
      <WideCtaSection 
        cityData={cityData}
        locationInfo={locationInfo}
      />
      <CouponSection />
      <FormTwoColumn 
        cityData={cityData}
        locationInfo={locationInfo}
      />
      <BadgeSection
        cityData={cityData}
        locationInfo={locationInfo}
      />
    </div>
  );
}

export default WhatWeTake;