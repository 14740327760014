import React from 'react';
import CouponSection from '../components/Coupons/CouponSection';
import FormTwoColumn from '../components/CTA/FormTwoColumn';
import WideCtaSection from '../components/CTA/WideCtaSection';
import BadgeSection from '../components/Badges/BadgeSection';
import HeroSlim from '../components/Hero/HeroSlim';
import ReviewSection from '../components/Testimonials/ReviewSection';

function Reviews({ cityData, locationInfo }) {
  return (
    <div>
        <HeroSlim 
          cityData={cityData}
          locationInfo={locationInfo}
          heroTitle="Reviews"
        />
        <ReviewSection 
          cityData={cityData}
          locationInfo={locationInfo}
        />
      <WideCtaSection 
        cityData={cityData}
        locationInfo={locationInfo}
      />
      <CouponSection />
      <FormTwoColumn 
        cityData={cityData}
        locationInfo={locationInfo}
      />
      <BadgeSection
        cityData={cityData}
        locationInfo={locationInfo}
      />
    </div>
  );
}

export default Reviews;