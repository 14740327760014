import React from 'react';
import { Link } from 'react-router-dom';
import phoneIcon from '../../assets/images/icon/phone.png';
import smsIcon from '../../assets/images/icon/sms.png';

function ContactOptionsRow({ locationInfo }) {
    return (
        <section className="row maintance-row">
            <div className="container"
                style={{
                    zIndex: 9,
                }}
            >
                <div className="row" 
                    style={{
                        backgroundImage: "linear-gradient(180deg, #FFB00F 50%, #FCA51D 50%)"
                    }}
                >
                <div className="col-sm-6">
                    <div className="media maintance-media">
                    <div className="media-left"><span><img src={phoneIcon} width='100px' alt="" /></span></div>
                    <div className="media-body">
                        <h4 className="this-title"><span>Call us</span> for a Quote</h4>
                        <p><Link to={'tel:'+locationInfo.tracking_phone}>{locationInfo.tracking_phone}</Link></p>
                    </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="media maintance-media">
                    <div className="media-left"><span><img src={smsIcon} width='100px' alt="" /></span></div>
                    <div className="media-body">
                        <h4 className="this-title"><span>Text us</span> a Pic for a Quote</h4>
                        <p><Link to={'tel:'+locationInfo.tracking_phone}>{locationInfo.tracking_phone}</Link></p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
}

export default ContactOptionsRow;