import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { handlePhoneChange, handleNameChange, handlePostalChange, handleZipChange, handleMessageChange, handleSubmit } from '../../utils/formHandlers';
import { fetchCountryFromIP } from '../../utils/fetchCountryFromIP';
// import './BookNowHome-bootstrap.css';
import './BookNowHome-style.css';
import headerLogoUSA from '../../assets/images/resources/logo-usa.png';
import headerLogoCanada from '../../assets/images/resources/logo-canada.png';
import headerLogo from '../../assets/images/resources/logo.png';

function BookNowHome() {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [zip, setZip] = useState('');
    const [message, setMessage] = useState('');
    const cityData = '';
    const [country, setCountry] = useState('');
    const [errors, setErrors] = useState({});

    useEffect(() => {
      // Fetch country from IP
      fetchCountryFromIP().then(ipData => {
          if (ipData) {
              setCountry(ipData.country_name);
          }
      });
  
      // Load Wistia scripts dynamically
      const script1 = document.createElement('script');
      script1.src = 'https://fast.wistia.com/embed/medias/bowthr0efr.jsonp';
      script1.async = true;
  
      const script2 = document.createElement('script');
      script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
      script2.async = true;
  
      document.body.appendChild(script1);
      document.body.appendChild(script2);
  
      // Clean up scripts when the component unmounts
      return () => {
          document.body.removeChild(script1);
          document.body.removeChild(script2);
      };
  }, []);
  

    const renderLogo = () => {
        if (country === 'United States') {
            return <span style={{ textAlign:'center' }}><img src={headerLogoUSA} width="250" alt="JUNK CHUCKERS USA" /></span>;
        } else if (country === 'Canada') {
            return <span style={{ textAlign:'center' }}><img src={headerLogoCanada} width="250" text-align="center" alt="JUNK CHUCKERS Canada" /></span>;
        }
        // Default logo if country is neither USA nor Canada, or if not yet determined
        return <span style={{ textAlign:'center' }}><img src={headerLogo} width="181" alt="JUNK CHUCKERS Logo" /></span>;
    };

    const formSubmitHandler = (event) => {
        event.preventDefault();

        // Perform validation
        if (!validateForm()) {
          return; // Stop form submission if validation fails
      }

        const formState = { name, phone, postalCode, zip, message };
        
        handleSubmit(formState, cityData, 
            () => {
                // Handle success, e.g., clear form, show success message
                navigate('/thankyou'); // Redirect to the thank you page
            }, 
            (error) => {
                // Handle error, e.g., show error message
                console.error("Error adding lead: ", error);
            }
        );
    };

    const validateForm = () => {
      const newErrors = {};
  
      // Mapping state values to field names
      const fieldValues = {
          name, phone, message, zip, postalCode
      };
  
      // List all fields that need to be validated
      const requiredFields = [
          'name', 'phone', 'message'
      ];
  
      // Check if each field is filled out
      requiredFields.forEach(field => {
          if (!fieldValues[field]) {
              newErrors[field] = 'This field is required';
          }
      });

      // Special case for zip and postalCode
    if (!fieldValues.zip && !fieldValues.postalCode) {
      newErrors.zip = 'Either Zip or Postal Code is required';
  }
  
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
  };

  const renderWistiaVideo = (videoId) => {
    return (
        <div className="wistia_responsive_padding" style={{padding: "56.25% 0 0 0", position: "relative"}}>
            <div className="wistia_responsive_wrapper" style={{height: "100%", left: "0", position: "absolute", top: "0", width: "100%"}}>
                <span className={`wistia_embed wistia_async_${videoId} popover=true popoverAnimateThumbnail=true videoFoam=true`} style={{display: "inline-block", height: "100%", position: "relative", width: "100%"}}></span>
            </div>
        </div>
    );
};

  return (
    <>
      <div className="w-layout-grid create-account-grid">
        <div className="create-account-left">
            {renderLogo()}
          <div className="create-account-left-contents">
            <div>
              <div className="form-block w-form">
                <div>
                  <div>
                    <div className="row d-flex justify-content-center">
                      <div className="col-lg-9" style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", background: "#fff", padding: "10px 50px 10px 50px", borderRadius: "15px" }} id="key1">
                        <div className="row text-center pt-3">
                          <div className=" d-flex justify-content-center">
                            <div className="col-md-12">
                              <div className="justify-content-center">
                                <h1 className="medium-heading" style={{ fontSize: '29px', color: '#444' }}>Complete the form to <br />
                                get your free junk removal quote:</h1>
                              </div>
                            <form 
                                onSubmit={formSubmitHandler} 
                                noValidate="noValidate"
                            >
                                <input type="text" name="location_id" onChange={(e) => handleZipChange(setZip, e)} autoComplete="off" value=''  hidden />
                                <div className="col-md-12 mb-2 mt-4">
                                <div >
                                    <input 
                                        type="text" 
                                        placeholder="Name:" 
                                        className={errors.name ? 'input-error form-input w-input' : 'form-input w-input'}
                                        name="name" 
                                        value={name}
                                        autoComplete="name"
                                        onChange={(e) => handleNameChange(setName, e)}
                                        maxLength={20}
                                        required
                                    />
                                  </div>
                                </div>
                                  <div className='col-md-12 mb-2 mt-4'>
                                      <input 
                                          type="text" 
                                          placeholder="Phone:" 
                                          name="phone" 
                                          autoComplete="tel"
                                          className={errors.name ? 'input-error form-input w-input' : 'form-input w-input'}
                                          value={phone}
                                          onChange={(e) => handlePhoneChange(setPhone, e)}
                                          maxLength={12}
                                          required
                                      />
                                  </div>
                                {/* Conditionally render based on the country */}
                                {country === 'Canada' && (
                                    <div className="col-md-12 mb-2 mt-4">
                                        <input 
                                            type="text" 
                                            placeholder="Postal code:" 
                                            name="zip" 
                                            autoComplete="zip"
                                            className={errors.name ? 'input-error form-input w-input' : 'form-input w-input'}
                                            value={postalCode.toUpperCase()}
                                            onChange={(e) => handlePostalChange(setPostalCode, e)}
                                            maxLength={6}
                                            required
                                        />
                                    </div>
                                )}

                                {country === 'United States' && (
                                    <div className="col-md-12 mb-2 mt-4">
                                        <input 
                                            type="text" 
                                            placeholder="Zip code:" 
                                            name="zip" 
                                            autoComplete="zip"
                                            className={errors.name ? 'input-error form-input w-input' : 'form-input w-input'}
                                            value={zip}
                                            onChange={(e) => handleZipChange(setZip, e)}
                                            maxLength={5}
                                            required
                                        />
                                    </div>
                                )}
                              
                              <div className="col-md-12 mb-4 mt-4">
                                
                                <textarea 
                                    name="message" 
                                    placeholder="What do you want us to remove?" 
                                    value={message} 
                                    className={errors.name ? 'input-error form-input w-input' : 'form-input w-input'}
                                    autoComplete="off"
                                    onChange={(e) => handleMessageChange(setMessage, e)}
                                    required
                                />
                              </div>
                              <div className="col-md-12">
                                    <button 
                                        type="submit" 
                                        className="button w-button"
                                    >
                                        Get Your Free Quote
                                    </button>
                              </div>
                              </form>
                            </div>

                            <div className="justify-content-center">
                              <div className="d-flex justify-content-center align-items-center">
                                <div className="col-md-6 mt-4 mb-3">
                                  <img src="https://junkchuckers.s3.amazonaws.com/price-match.png" alt="Junk Removal Company" style={{ width: '49%' }} />
                                  <img src="https://junkchuckers.s3.amazonaws.com/satisfaction-badge.png" alt="Junk Removal Company" style={{ width: '49%' }} />
                                </div>
                                <div className="small-text checklist-item" style={{ lineHeight: '35px' }}>
                                  <div className="check-circle">
                                    <img src="https://junkchuckers.s3.amazonaws.com/web-imgs/icon-check-dark-small.svg" width="14" alt="" />
                                  </div>
                                  <strong className="checklist-item-text">We do all the lifting and loading.</strong>
                                </div>
                                <div className="small-text checklist-item" style={{ lineHeight: '35px' }}>
                                  <div className="check-circle">
                                    <img src="https://junkchuckers.s3.amazonaws.com/web-imgs/icon-check-dark-small.svg" width="14" alt="" />
                                  </div>
                                  <strong className="checklist-item-text">40% cheaper than the "other" guys.</strong>
                                </div>
                                <div className="small-text checklist-item" style={{ lineHeight: '35px' }}>
                                  <div className="check-circle">
                                    <img src="https://junkchuckers.s3.amazonaws.com/web-imgs/icon-check-dark-small.svg" width="14" alt="" />
                                  </div>
                                  <strong className="checklist-item-text text-primary-4">BONUS: We have inflation-free prices.</strong>
                                </div>
                              </div>
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="create-account-right">
            <div className="create-account-review">
            <div className="review">
                <div className="small-text">Here's Charlie ✌️ one of our friendly phone reps - sharing a fantastic tip 🚀 How-to choose between Full Service & Renting a Bin:</div>
                {renderWistiaVideo('bowthr0efr')}
                <div className="small-text"><em>32 seconds</em></div>
            </div>
            <div className="review below-review">
                <div className="small-text">Here's a message from Fred 👋 one of our Regional Managers:</div>
                {renderWistiaVideo('kc3vbjlezn')}
                <div className="small-text"><em>11 seconds</em></div>
            </div>
            </div>
            <div className="justify-content-center">
                <div className="review-score-wrapper">
                    <h3 className="no-bottom-margin text-white">4.91/5</h3>
                    <div className="review-stars-wrapper">
                        <img src="https://junkchuckers.s3.amazonaws.com/web-imgs/icon-star_1.svg" alt=""/>
                        <img src="https://junkchuckers.s3.amazonaws.com/web-imgs/icon-star_1.svg" alt=""/>
                        <img src="https://junkchuckers.s3.amazonaws.com/web-imgs/icon-star_1.svg" alt=""/>
                        <img src="https://junkchuckers.s3.amazonaws.com/web-imgs/icon-star_1.svg" alt=""/>
                        <img src="https://junkchuckers.s3.amazonaws.com/web-imgs/icon-star_1.svg" alt=""/>
                    </div>
                </div>
                <div className="small-text text-white">
                    From 
                    <strong className="text-bold text-white">1,400+</strong>
                     Customer Reviews
                </div>
            </div>
        </div>
    </div>
    </>
    );
}

export default BookNowHome;