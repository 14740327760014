import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { handlePhoneChange, handleNameChange, handlePostalChange, handleZipChange, handleMessageChange, handleSubmit } from '../../utils/formHandlers';
import { fetchCountryFromIP } from '../../utils/fetchCountryFromIP';
import phoneIcon from '../../assets/images/icon/phone-icon-1.png';
import { FaArrowRight } from 'react-icons/fa';

function QuoteForm({ cityData, locationInfo }) {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [zip, setZip] = useState('');
    const [message, setMessage] = useState('');
    const [country, setCountry] = useState('');
    const [errors, setErrors] = useState({});

    useEffect(() => {
        fetchCountryFromIP().then(ipData => {
            if (ipData) {
                setCountry(ipData.country_name);
            }
        });
    }, []);

    const formSubmitHandler = (event) => {
        event.preventDefault();

        // Perform validation
        if (!validateForm()) {
            return; // Stop form submission if validation fails
        }

        const formState = { name, phone, postalCode, zip, message };
        
        handleSubmit(formState, cityData, 
            () => {
                // Handle success, e.g., clear form, show success message
                navigate('/thankyou'); // Redirect to the thank you page
            }, 
            (error) => {
                // Handle error, e.g., show error message
                console.error("Error adding lead: ", error);
            }
        );
    };

    const validateForm = () => {
        const newErrors = {};
    
        // Mapping state values to field names
        const fieldValues = {
            name, phone, message
        };
    
        // List all fields that need to be validated
        const requiredFields = [
            'name', 'phone', 'message'
        ];
    
        // Check if each field is filled out
        requiredFields.forEach(field => {
            if (!fieldValues[field]) {
                newErrors[field] = 'This field is required';
            }
        });
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    return (
        <section className="contact-one">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6">
                        <div className="contact-one__left">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">FREE QUOTE</span>
                                <h2 className="section-title__title">Complete the form to <span>get your free junk removal quote:</span></h2>
                            </div>
                            {/* <p className="contact-one__text">If you need to repair or replace your Plumbing system, call
                                today and talk to one of our Plumbing specialists. They'll answer all your questions and
                                arrange.</p> */}
                                <div className="video-gallery__service-points-box">
                                <ul className="list-unstyled video-gallery__service-points">
                                    <li>
                                        <div className="icon">
                                            <i className="fa fa-check"></i>
                                        </div>
                                        <div className="text">
                                            <p>All-inclusive rates</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="fa fa-check"></i>
                                        </div>
                                        <div className="text">
                                            <p>Up to 40% cheaper than others</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="fa fa-check"></i>
                                        </div>
                                        <div className="text">
                                            <p>No hidden fees, ever!</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="contact-one__phone">
                                <div className="contact-one__phone-img">
                                    <img src={phoneIcon} alt="" />
                                </div>
                                <div className="contact-one__phone-content">
                                    <p>Speak to someone right now, call:</p>
                                    <h4>
                                        <Link to={'tel:'+locationInfo.tracking_phone}>{locationInfo.tracking_phone}</Link>    
                                    </h4>
                                </div>
                            </div>
                        </div>
                        {!cityData.locationId && (
                        <div className="col-md-2">
                            <div className="contact-one__input-box">
                            {country === 'Canada' && (
                                <input 
                                        type="text" 
                                        placeholder="Postal code:" 
                                        name="zip" 
                                        autoComplete="zip"
                                        value={postalCode.toUpperCase()}
                                        onChange={(e) => handlePostalChange(setPostalCode, e)}
                                        maxLength={6}
                                        required
                                    /> 
                                )}
                                {country === 'Canada' && (
                                    <input 
                                        type="text" 
                                        placeholder="Zip code:" 
                                        name="zip" 
                                        autoComplete="zip"
                                        value={zip}
                                        onChange={(e) => handleZipChange(setZip, e)}
                                        maxLength={5}
                                        required
                                    />
                                )}
                            </div>
                        </div>
                        )}
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="contact-one__right">
                            <form 
                                onSubmit={formSubmitHandler} 
                                className="contact-one__form contact-form-validated"
                                noValidate="noValidate"
                            >
                                <input type="text" name="location_id" onChange={(e) => handleZipChange(setZip, e)} value={cityData.locationId} autoComplete="off" hidden  />
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="contact-one__input-box">
                                        <input 
                                                type="text" 
                                                placeholder="Name:" 
                                                name="name" 
                                                autoComplete="name"
                                                value={name}
                                                className={errors.name ? 'input-error' : ''}
                                                onChange={(e) => handleNameChange(setName, e)}
                                                maxLength={20}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-12">
                                        <div className="contact-one__input-box">
                                        <input 
                                                type="text" 
                                                placeholder="Phone:" 
                                                name="phone" 
                                                autoComplete="tel"
                                                value={phone}
                                                className={errors.name ? 'input-error' : ''}
                                                onChange={(e) => handlePhoneChange(setPhone, e)}
                                                maxLength={12}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="contact-one__input-box text-message-box">
                                        <textarea 
                                                name="message" 
                                                placeholder="What do you want us to remove?" 
                                                value={message} 
                                                autoComplete="off"
                                                className={errors.name ? 'input-error' : ''}
                                                onChange={(e) => handleMessageChange(setMessage, e)}
                                                required
                                            />
                                        </div>
                                        <div className="contact-one__btn-box">
                                            <button 
                                                type="submit" 
                                                className="thm-btn contact-one__btn"
                                            >Get quote! <FaArrowRight />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default QuoteForm;