import React from 'react';
import { Link } from 'react-router-dom';
import footerWidgetImg from '../../assets/images/resources/footer-trucklogo.png';
import footerMascot from '../../assets/images/resources/footer-mascot.png';

function Footer({ cityData, locationInfo }) {
    return (
        <footer className="site-footer">
            <div className="site-footer__top">
                <div className="container">
                    <div className="site-footer__inner">
                        <div className="site-footer__contact-info">
                            <ul className="site-footer__contact-points list-unstyled">
                                <li>
                                <img src={footerMascot} className="site-footer__mascot" alt="Footer logo" />
                                </li>
                                <li>
                                    <div className="icon">
                                        <span className="icon-phone"></span>
                                    </div>
                                    <div className="content">
                                        <h4>Need junk removal near {cityData.locationName}? <Link to={'tel:'+locationInfo.tracking_phone}>Call now: {locationInfo.tracking_phone}</Link></h4>
                                        {/* <Link to={'tel:'+locationInfo.tracking_phone}>Call or text us: {locationInfo.tracking_phone}</Link> */}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-footer__middle">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="footer-widget__column footer-widget__useful-links">
                                <div className="footer-widget__title-box">
                                    <h3 className="footer-widget__title">Contact us</h3>
                                </div>
                                <ul className="footer-widget__useful-links-list list-unstyled">
                                    <li>
                                        <p>JUNK CHUCKERS {cityData.locationName}</p>
                                    </li>
                                    <li>
                                        <Link to={'tel:'+locationInfo.tracking_phone}>Phone: {locationInfo.tracking_phone}</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="footer-widget__column footer-widget__services">
                                <div className="footer-widget__title-box">
                                    <h3 className="footer-widget__title">Popular links</h3>
                                </div>
                                <ul className="footer-widget__useful-links-list list-unstyled">
                                    <li>
                                        <Link to="">Junk removal near {cityData.locationName}</Link>
                                    </li>
                                    <li>
                                        <Link to="">Junk removal {cityData.locationName}</Link>
                                    </li>
                                    <li>
                                        <Link to="">Hoarding {cityData.locationName}</Link>
                                    </li>
                                    <li>
                                        <Link to="">Rubbish removal {cityData.locationName}</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="float-bob-y">
                                <div className="footer-widget__img">
                                <img src={footerWidgetImg} alt="Footer Truck" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-footer__bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="site-footer__bottom-inner">
                                <p className="site-footer__bottom-text">
                                    © <Link to="">JUNK CHUCKERS</Link>
                                </p>
                                <ul className="site-footer__bottom-text-two list-unstyled">
                                    <li>
                                        <Link to="privacy">Privacy Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="https://www.chizebrands.com">CHIZE Brands</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;