import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { handlePhoneChange, handleNameChange, handlePostalChange, handleZipChange, handleMessageChange, handleSubmit } from '../../utils/formHandlersBookNow';
import { fetchCountryFromIP } from '../../utils/fetchCountryFromIP';
import phoneIcon from '../../assets/images/icon/phone-icon-1.png';
import { FaArrowRight } from 'react-icons/fa';
import { loadGoogleMapsScript } from '../../utils/googleMapScript';

function BookNowMicrosite({ cityData, locationInfo }) {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [zip, setZip] = useState('');
    const [message, setMessage] = useState('');
    const [country, setCountry] = useState('');
    const [timeSlot, setTimeSlot] = useState(''); // State for the selected time slot
    const [endTime, setEndTime] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const dates = generateNext8Days();
    const addressInputRef = useRef(null);
    const [address, setAddress] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [countryName, setCountryName] = useState('');
    const [unit, setUnit] = useState('');
    const [propertyType, setPropertyType] = useState('');
    const [isTimeSensitive, setisTimeSensitive] = useState('');
    const [errors, setErrors] = useState({});


    useEffect(() => {
        // Fetch country information
        fetchCountryFromIP().then(ipData => {
            if (ipData) {
                setCountry(ipData.country_name);
            }
        });
    
        // Load Google Maps script and initialize autocomplete
        loadGoogleMapsScript(() => {
            const autocomplete = new window.google.maps.places.Autocomplete(addressInputRef.current);
            autocomplete.setFields(['address_components', 'formatted_address']);
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                if (place.address_components) {
                    setAddress(place.formatted_address);
                    parseAddressComponents(place.address_components);
                }
            });
        });
    }, []);
    
    const parseAddressComponents = (components) => {
        const componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'long_name',
            postal_code: 'short_name',
        };

        let streetAddress = '';
        components.forEach((component) => {
            const addressType = component.types[0];
            if (componentForm[addressType]) {
                const val = component[componentForm[addressType]];
                if (addressType === 'street_number') {
                    streetAddress = val;
                } else if (addressType === 'route') {
                    streetAddress += ' ' + val;
                } else if (addressType === 'locality') {
                    setCity(val);
                } else if (addressType === 'administrative_area_level_1') {
                    setState(val);
                } else if (addressType === 'postal_code') {
                    setZipCode(val);
                } else if (addressType === 'country') {
                    setCountryName(val);
                }
            }
        });
        setStreet(streetAddress);
    };

    const formSubmitHandler = (event) => {
        event.preventDefault();
        console.log("Form submit handler triggered");
        // Perform validation
        if (!validateForm()) {
            return; // Stop form submission if validation fails
        }

        const formState = {
            name, phone, message, 
            street, city, state, unit, countryName, isTimeSensitive, zipCode, endTime, propertyType, selectedDate, timeSlot
        };
        
        handleSubmit(formState, cityData, 
            () => navigate('/thankyou'), // Handle success
            (error) => console.error("Error adding lead: ", error) // Handle error
        );
    };    

    // Array of predefined time slots
    const timeSlots = [
        { label: "9:00 AM to 11:00 AM", value: "9:00" },
        { label: "11:00 AM to 1:00 PM", value: "11:00" },
        { label: "1:00 PM to 3:00 PM", value: "13:00" },
        { label: "3:00 PM to 5:00 PM", value: "15:00" },
    ];

    function generateNext8Days() {
        const dates = [];
        let date = new Date();
    
        while (dates.length < 8) {
            // Check if the current day is not Sunday
            if (date.getDay() !== 0) {
                const displayDate = date.toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric' });
                const valueDate = date.toISOString().split('T')[0];
    
                dates.push({ label: displayDate, value: valueDate });
            }
    
            // Move to the next day
            date.setDate(date.getDate() + 1);
        }
    
        return dates;
    }

    const handleTimeSlotChange = (value) => {
        setTimeSlot(value);
        
        // Assuming value is in 'HH:MM' 24-hour format
        if (value) {
            const [hours, minutes] = value.split(':').map(Number);
            const endTime = new Date();
            endTime.setHours(hours + 2, minutes);
            
            // Format endTime to 'HH:MM' format
            const formattedEndTime = endTime.toTimeString().substring(0, 5);
            setEndTime(formattedEndTime);
        } else {
            setEndTime('');
        }
    };
    
    const validateForm = () => {
        const newErrors = {};
    
        // Mapping state values to field names
        const fieldValues = {
            name, phone, message, 
            street, city, state, zipCode, countryName, 
            selectedDate, timeSlot, propertyType, isTimeSensitive
        };
    
        // List all fields that need to be validated
        const requiredFields = [
            'name', 'phone', 'message', 
            'street', 'city', 'state', 'countryName', 
            'selectedDate', 'timeSlot', 'propertyType', 'isTimeSensitive'
        ];
    
        // Check if each field is filled out
        requiredFields.forEach(field => {
            if (!fieldValues[field]) {
                newErrors[field] = 'This field is required';
                console.log(`Validation failed for ${field}`); // Log the field that failed validation
            }
        });
    
        // Special case for unit field when property type is Apartment
        if (propertyType === "Apartment" && !unit) {
            newErrors.unit = 'This field is required';
            console.log("Validation failed for unit field"); // Log the unit field failure
        }
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    
    

    return (
        <section className="contact-one">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6">
                        <div className="contact-one__left">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">Schedule Removal</span>
                                <h2 className="section-title__title">Book your junk removal now:</h2>
                            </div>
                                {/* <p className="contact-one__text">If you need to repair or replace your Plumbing system, call
                                today and talk to one of our Plumbing specialists. They'll answer all your questions and
                                arrange.</p> */}
                                <div className="video-gallery__service-points-box">
                                <ul className="list-unstyled video-gallery__service-points">
                                    <li>
                                        <div className="icon">
                                            <i className="fa fa-check"></i>
                                        </div>
                                        <div className="text">
                                            <p>All-inclusive rates</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="fa fa-check"></i>
                                        </div>
                                        <div className="text">
                                            <p>Up to 40% cheaper than others</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="fa fa-check"></i>
                                        </div>
                                        <div className="text">
                                            <p>No hidden fees, ever!</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="contact-one__phone">
                                <div className="contact-one__phone-img">
                                    <img src={phoneIcon} alt="" />
                                </div>
                                <div className="contact-one__phone-content">
                                    <p>Speak to someone right now, call:</p>
                                    <h4>
                                        <Link to={'tel:'+locationInfo.tracking_phone}>{locationInfo.tracking_phone}</Link>    
                                    </h4>
                                </div>
                            </div>
                        </div>
                        {!cityData.locationId && (
                        <div className="col-md-2">
                            <div className="contact-one__input-box">
                            {country === 'Canada' && (
                                <input 
                                        type="text" 
                                        placeholder="Postal code:" 
                                        name="zip" 
                                        autoComplete="zip"
                                        value={postalCode.toUpperCase()}
                                        onChange={(e) => handlePostalChange(setPostalCode, e)}
                                        maxLength={6}
                                        required
                                    /> 
                                )}
                                {country === 'Canada' && (
                                    <input 
                                        type="text" 
                                        placeholder="Zip code:" 
                                        name="zip" 
                                        autoComplete="zip"
                                        value={zip}
                                        onChange={(e) => handleZipChange(setZip, e)}
                                        maxLength={5}
                                        required
                                    />
                                )}
                            </div>
                        </div>
                        )}
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="contact-one__right">
                            <form 
                                onSubmit={formSubmitHandler} 
                                className="contact-one__form contact-form-validated"
                                noValidate="noValidate"
                                autoComplete='false'
                            >
                                <input type="text" name="location_id" onChange={(e) => handleZipChange(setZip, e)} value={cityData.locationId} autoComplete="off" hidden  />
                                <div className="row">
                                    <div className="col-xl-6">
                                        <div className="contact-one__input-box">
                                            <input 
                                                type="text" 
                                                placeholder="Name:" 
                                                name="name" 
                                                autoComplete="off"
                                                value={name}
                                                onChange={(e) => handleNameChange(setName, e)}
                                                maxLength={20}
                                                className={errors.name ? 'input-error' : 'form-control'}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="contact-one__input-box">
                                            <input 
                                                type="text" 
                                                placeholder="Phone:" 
                                                name="phone" 
                                                autoComplete="off"
                                                value={phone}
                                                onChange={(e) => handlePhoneChange(setPhone, e)}
                                                className={errors.name ? 'input-error' : 'form-control'}
                                                maxLength={12}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="contact-one__input-box">
                                        <select 
                                            value={selectedDate}
                                            onChange={(e) => setSelectedDate(e.target.value)}
                                            className={errors.name ? 'input-error' : 'form-control'}
                                            style={{ height: '80px', fontSize: '20px', paddingLeft: '30px', fontWeight: '700', color: '#444', width: '100%' }}
                                            required
                                        >
                                            <option value="">Select a date:</option>
                                            {dates.map((date, index) => (
                                                <option key={index} value={date.value}>{date.label}</option>
                                            ))}
                                        </select>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="contact-one__input-box">
                                            <select 
                                                value={timeSlot}
                                                onChange={(e) => handleTimeSlotChange(e.target.value)}
                                                className={errors.name ? 'input-error' : 'form-control'}
                                                style={{ height: '80px', fontSize: '20px', paddingLeft: '30px', fontWeight: '700', color: '#444', width: '100%' }}
                                                required
                                            >
                                                <option value="">Choose window time:</option>
                                                {timeSlots.map((slot, index) => (
                                                    <option key={index} value={slot.value}>{slot.label}</option>
                                                ))}
                                            </select>
                                            <input 
                                                type="text" 
                                                placeholder="End time:" 
                                                name="endTime" 
                                                value={endTime} // Use endTime state here
                                                readOnly // Make this input read-only as it's auto-calculated
                                                hidden
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-12">
                                        <div className="contact-one__input-box">
                                        <select 
                                                value={isTimeSensitive}
                                                onChange={(e) => setisTimeSensitive(e.target.value)}
                                                className={errors.name ? 'input-error' : 'form-control'}
                                                style={{ height: '80px', fontSize: '20px', paddingLeft: '30px', fontWeight: '700', color: '#444', width: '100%' }}
                                                required
                                            >
                                                <option value="">Are you flexible on Time?</option>
                                                    <option value="false">Yes - I'm flexible on the time.</option>
                                                    <option value="true">No - I require this time only.</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="contact-one__input-box">
                                        <select 
                                                value={propertyType}
                                                onChange={(e) => setPropertyType(e.target.value)}
                                                className={errors.name ? 'input-error' : 'form-control'}
                                                style={{ height: '80px', fontSize: '20px', paddingLeft: '30px', fontWeight: '700', color: '#444', width: '100%' }}
                                                required
                                            >
                                                <option value="">Property type:</option>
                                                    <option value="House">House</option>
                                                    <option value="Apartment">Condo/Apartment</option>
                                                    <option value="Business">Business</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                    {propertyType === "Apartment" && (
                                        <div className="contact-one__input-box">
                                            <input 
                                                type="text" 
                                                placeholder="Unit #:" 
                                                name="unit"
                                                value={unit}
                                                className={errors.name ? 'input-error' : 'form-control'}
                                                onChange={(e) => setUnit(e.target.value)}
                                                maxLength={12}
                                            />
                                        </div>
                                    )}
                                    </div>
                                    <div className="col-xl-12">
                                        <div className="contact-one__input-box">
                                            <input 
                                                ref={addressInputRef}
                                                type="text" 
                                                placeholder="Address:" 
                                                value={address}
                                                autoComplete="off"
                                                className={errors.name ? 'input-error' : 'form-control'}
                                                onChange={(e) => setAddress(e.target.value)}
                                                required
                                            />
                                            <input type="text" value={street} placeholder="Street" onChange={(e) => setStreet(e.target.value)} hidden />
                                            <input type="text" value={city} placeholder="City" onChange={(e) => setCity(e.target.value)} hidden />
                                            <input type="text" value={state} placeholder="State" onChange={(e) => setState(e.target.value)} hidden />
                                            <input type="text" value={zipCode} placeholder="Zip Code" onChange={(e) => setZipCode(e.target.value)} hidden />
                                            <input type="text" value={countryName} placeholder="Country" onChange={(e) => setCountryName(e.target.value)} hidden />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="contact-one__input-box text-message-box">
                                            <textarea 
                                                name="message" 
                                                placeholder="What do you want us to remove?" 
                                                value={message} 
                                                autoComplete="off"
                                                className={errors.name ? 'input-error' : 'form-control'}
                                                onChange={(e) => handleMessageChange(setMessage, e)}
                                                required
                                            />
                                        </div>
                                        <div className="contact-one__btn-box">
                                            <button 
                                                type="submit" 
                                                className="thm-btn contact-one__btn"
                                            >
                                                Book Now <FaArrowRight />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BookNowMicrosite;