import React from 'react';
import ThankYouSection from '../components/ThankYou/ThankYouSection';

function ThankYou({ locationInfo }) {
  return (
    <div>
        <ThankYouSection />
    </div>
  );
}

export default ThankYou;