import React, { useContext } from 'react';
import { Link } from 'react-router-dom'; 
import CurrentCityContext from '../../CurrentCityContext';
import rateBanner from '../../assets/images/resources/truck-rates.png';

function HowItWorksSection({ cityData, locationInfo }) {
    const { currentCity } = useContext(CurrentCityContext);

    // Function to create URL with city prefix
    const createCityLink = (path) => {
        return currentCity ? `/${currentCity}${path}` : path;
    };

    return (
        <section className="why-choose-one">
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-lg-5">
                        <div className="why-choose-one__left">
                            <div className="why-choose-one__img-box">
                                <div className="why-choose-one__img">
                                    <img src={rateBanner} alt="" />
                                </div>
                                <div className="why-choose-one__trusted">
                                    <div className="why-choose-one__trusted-icon">
                                        <span className="icon-reliability"></span>
                                    </div>
                                    <div className="why-choose-one__trusted-content">
                                        <h3>400+ happy customers</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-7">
                        <div className="why-choose-one__right">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">HOW IT WORKS</span>
                                <h2 className="section-title__title">Junk removal made easy</h2>
                            </div>
                            <p className="why-choose-one__text">
                                    We've made it really easy to get rid of your junk. Just follow these simple steps:
                                </p>
                            <ul className="list-unstyled why-choose-one__points">
                                <h4>Step 1: Get your free quote with one of our convenient options:</h4>
                                <br />
                                <li>
                                    <div className="icon">
                                        <span className="icon-comment"></span>
                                    </div>
                                    <div className="text">
                                        <p>
                                            Option 1: Call or Text us at <Link to={'tel:'+locationInfo.tracking_phone}>{locationInfo.tracking_phone}</Link>. 
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <span className="icon-comment"></span>
                                    </div>
                                    <div className="text">
                                        <p>
                                            Option 2: <Link to={createCityLink("/get-quote")}>Request a quote online here</Link>.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <span className="icon-comment"></span>
                                    </div>
                                    <div className="text">
                                        <p>
                                            Option 3: <Link to={createCityLink("/book-now")}>Schedule an in-person quote here</Link>. We'll come to you!
                                        </p>
                                    </div>
                                </li>
                            </ul>
                            <br />
                            <ul className="list-unstyled why-choose-one__points">
                                <h4>Step 2: Like the quote? We can do the work same day!</h4>
                                <br />
                                <li>
                                    <div className="icon">
                                        <span className="icon-comment"></span>
                                    </div>
                                    <div className="text">
                                        <p>
                                            Simply point and it's gone! That's it... 
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HowItWorksSection;