import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import CurrentCityContext from '../../CurrentCityContext';
import heroImage from '../../assets/images/resources/page-header-img-1.png';

function HeroSlim({ cityData, locationInfo, heroTitle }) {
    const { currentCity } = useContext(CurrentCityContext);

    // Function to create URL with city prefix
    const createCityLink = (path) => {
        return currentCity ? `/${currentCity}${path}` : path;
    };

    return (
        <section className="page-header">
            <div className="container">
                <div className="page-header__inner">
                    <div className="page-header__img">
                        <img src={heroImage} alt="" />
                    </div>
                    <h2>{heroTitle}</h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li>
                        <Link to={createCityLink("/")}>
                                        JUNK CHUCKERS {cityData.locationName}
                                    </Link>
                            </li>
                        <li><span>/</span></li>
                        <li>{heroTitle}</li>
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default HeroSlim;