import React from 'react';


function ReviewSection({ cityData, locationInfo }) {
    return (
        <section className="testimonials-page">
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="testimonials-page__sec-title">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">Reviews</span>
                                <h2 className="section-title__title">Some reviews from our 400+ happy customers:</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="testimonial-one__single">
                            <div className="testimonial-one__inner">
                                <div className="testimonial-one__quote">
                                    <span className="icon-quotation"></span>
                                </div>
                                <div className="testimonial-one__text">
                                    <p>" I want to express my gratitude to Jason and Jesse. 
                                        Jason was wonderful to speak with over the phone and he followed up when he said he would. 
                                        Jesse and Noah were very professional and respectful of my home, and went above and beyond what I had expected. " </p>
                                </div>
                            </div>
                            <div className="testimonial-one__client-info">
                                <div className="testimonial-one__client-img">
                                    <img src="assets/images/testimonial/testimonial-1-1.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="testimonial-one__single">
                            <div className="testimonial-one__inner">
                                <div className="testimonial-one__quote">
                                    <span className="icon-quotation"></span>
                                </div>
                                <div className="testimonial-one__text">
                                    <p>Fantastic service from start to finish! Jason's team was punctual, efficient, and very courteous. They cleared everything quickly and left the area spotless. Highly recommend their services."</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="testimonial-one__single">
                            <div className="testimonial-one__inner">
                                <div className="testimonial-one__quote">
                                    <span className="icon-quotation"></span>
                                </div>
                                <div className="testimonial-one__text">
                                    <p>Incredible experience with this junk removal service. Jesse and his crew were thorough, respectful of our property, and went the extra mile to ensure satisfaction. Worth every penny!</p>
                                </div>
                            </div>
                            <div className="testimonial-one__client-info">
                                <div className="testimonial-one__client-img">
                                    <img src="assets/images/testimonial/testimonial-1-1.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="testimonial-one__single">
                            <div className="testimonial-one__inner">
                                <div className="testimonial-one__quote">
                                    <span className="icon-quotation"></span>
                                </div>
                                <div className="testimonial-one__text">
                                    <p>Top-notch service! The team was friendly, professional, and super efficient. They handled a big job with ease and left no mess behind. Will definitely use them again.</p>
                                </div>
                            </div>
                            <div className="testimonial-one__client-info">
                                <div className="testimonial-one__client-img">
                                    <img src="assets/images/testimonial/testimonial-1-2.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="testimonial-one__single">
                            <div className="testimonial-one__inner">
                                <div className="testimonial-one__quote">
                                    <span className="icon-quotation"></span>
                                </div>
                                <div className="testimonial-one__text">
                                    <p>Superb job by the crew! They were on time, worked quickly, and were extremely polite. They even swept up after clearing the junk. Very impressed with their service.</p>
                                </div>
                            </div>
                            <div className="testimonial-one__client-info">
                                <div className="testimonial-one__client-img">
                                    <img src="assets/images/testimonial/testimonial-1-1.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ReviewSection;