import React from 'react';
import additionalServicesImage from '../../assets/images/resources/guys-lifting-mattress.png';

function AdditionalServicesSectionAlt() {
    return (
        <section className="additional-services-two">
            <div className="additional-services-two__bg"></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-lg-7">
                        <div className="additional-services-two__left">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">No job too big, or too small</span>
                                <h2 className="section-title__title">We lift &amp; load it all</h2>
                            </div>
                            <p className="additional-services-two__text">
                                We're happy to remove anything from a single couch to clearing out an entire house or apartment. 
                                No job is too big or too small.
                            </p>
                            <div className="additional-services-two__points-box">
                                <ul className="additional-services-two__points list-unstyled">
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>Old furniture</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>Appliances</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>Mattresses</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>TV's, electronics</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>Pianos</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>Exercise equipment</p>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="additional-services-two__points list-unstyled">
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>Garbage, rubbish</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>Renovation debris</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>Shed removal</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>Outdoor furniture</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>Hot tub removal</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>Carpeting, rugs</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>... and more!</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-5">
                        <div className="additional-services-two__right">
                            <div className="additional-services-two__img">
                                <img src={additionalServicesImage} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AdditionalServicesSectionAlt;