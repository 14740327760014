import React from 'react';
import badgeGroup from './badgeGroup';

function BadgeSection({ cityData, locationInfo }) {
    // Check the badge group based on cityData
    const group = badgeGroup[cityData.badgeGroup] || badgeGroup['default'];

    return (
        <section className="brand-one">
            <div className="container">
                <h4 className="brand-one__title">All year supporters of</h4>
                <div className="brand-container">
                    {Object.values(group).map((badgeName, index) => (
                        <img
                            key={index}
                            src={require(`../../assets/images/badges/${badgeName}.png`)}
                            alt={`Brand ${index + 1}`}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
}

export default BadgeSection;
